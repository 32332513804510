import { AppProps } from "next/app"
import { appWithTranslation } from 'next-i18next'

import nextI18NextConfig from 'next-i18next.config.js'
import "styles/sass/style.scss"

const App = ({ Component, pageProps }: AppProps) => {
    return <Component {...pageProps} />
}

export default appWithTranslation(App, nextI18NextConfig )


